<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-card outlined class="pt-2 pb-3">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-slot-machine-outline"
                                    clearable
                                    solo
                                    dense
                                    v-model="machine"
                                    :items="machines"
                                    item-value="mach_id"
                                    item-text="descr"
                                    label="Machine"
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="start_period"
                                        label="Start Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-1 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="start_period"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="end_period"
                                        label="End Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        class="ma-0 pa-1 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="end_period"
                                        type="month"
                                        no-title
                                        @input="menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    v-model="brand"
                                    :items="brands"
                                    item-value="brand_id"
                                    item-text="descr"
                                    label="Brand"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                    @change="(event) => getColour()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-coat-rack"
                                    clearable
                                    solo
                                    v-model="coat_mass"
                                    :items="coat_masss"
                                    item-value="coat_mass"
                                    :item-text="item => 'AS' + item.coat_mass"
                                    label="Coat (AS - AZ)"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-star-circle"
                                    clearable
                                    solo
                                    v-model="grade"
                                    :items="grades"
                                    item-value="grade_id"
                                    item-text="grade_id"
                                    label="Grade"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                    label="Thickness"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-palette"
                                    clearable
                                    solo
                                    v-model="colour"
                                    :items="colours"
                                    item-value="color_id"
                                    item-text="descr"
                                    label="Colour"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-quality-high"
                                    clearable
                                    solo
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="quality_id"
                                    item-text="quality_id"
                                    label="Quality"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1 border-12" style="padding: 19px;" color="info" elevation="2" @click="search()"><v-icon class="mr-1">mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="5" md="2">
                            <v-btn
                                class="mr-2 rounded-l mt-7"
                                color="error"
                                elevation="2"
                                large
                                @click="search()"
                            >
                                <v-icon dark>
                                    mdi-magnify
                                </v-icon>
                                Search
                            </v-btn>
                        </v-col>          -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" id="result1">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-card-title>
                                Result
                            </v-card-title>
                            <v-card-text>
                                <div id="chartContainer1" style="height: 450px; width: 100%;"></div>
                            </v-card-text>                           
                            
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" class="mt-5">
                <v-container fluid >
                    <v-row>
                        <v-col cols="12" id="showDetail">
                            <v-card>
                                <v-card-text>
                                    <h6>Result Detail</h6>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mt-5">
                        <v-col cols="12" xs="12" 
                        sm="6" 
                        md="4">
                            <v-card outlined>
                                <v-card-text>
                                    <div id="container2" style="height: 370px; width: 100%;"></div>
                                </v-card-text> 
                            </v-card>
                        </v-col>
                        <v-col cols="12" xs="12" 
                        sm="6" 
                        md="4">
                            <v-card outlined>
                                <v-card-text>
                                    <div id="container3" style="height: 370px; width: 100%;"></div>
                                </v-card-text> 
                            </v-card>
                        </v-col>
                        <v-col cols="12" xs="12" 
                        sm="6" 
                        md="4">
                            <v-card outlined>
                                <v-card-text>
                                    <div id="container4" style="height: 370px; width: 100%;"></div>
                                </v-card-text> 
                            </v-card>
                        </v-col>
                        <v-col cols="12" xs="12" 
                        sm="6" 
                        md="3">
                            <v-card outlined>
                                <v-card-text>
                                    <div id="container5" style="height: 370px; width: 100%;"></div>
                                </v-card-text> 
                            </v-card>
                        </v-col>
                        <v-col cols="12" xs="12" 
                        sm="6" 
                        md="3">
                            <v-card outlined>
                                <v-card-text>
                                    <div id="container6" style="height: 370px; width: 100%;"></div>
                                </v-card-text> 
                            </v-card>
                        </v-col>
                        <v-col cols="12" xs="12" 
                        sm="6" 
                        md="3">
                            <v-card outlined>
                                <v-card-text>
                                    <div id="container7" style="height: 370px; width: 100%;"></div>
                                </v-card-text> 
                            </v-card>
                        </v-col>
                        <v-col cols="12" xs="12" 
                        sm="6" 
                        md="3">
                            <v-card outlined>
                                <v-card-text>
                                    <div id="container8" style="height: 370px; width: 100%;"></div>
                                </v-card-text> 
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    data() {
        return {
            detail: false,
            start_period: '',
            end_period: '',
            menu: false,
            modal: false,
            loading: false,
            brands: [],
            brand: '',
            coat_masss: [],
            coat_mass: '',
            grades: [],
            grade: '',
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            colours: [],
            colour: '',
            qualities: [],
            quality: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Mill Data Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Production Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            machines: [],
            machine: '',
            x:  window.matchMedia("(max-width: 991px)")
        }
    },
    mounted(){
        this.getMachine()
        this.getBrand()
        this.getCoatMass()
        this.getGrade()
        this.getThick()
        this.getWidth()
        this.getColour()
        this.getQuality()
    },
    methods:{
        async getMachine(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/machineGl`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.machines = res.data
            });
        },
        async getBrand(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/brand`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.brands = res.data
            });
        },
        async getCoatMass(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/coat_mass`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.coat_masss = res.data
            });
        },
        async getGrade(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/grade`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.grades = res.data
            });
        },
        async getThick(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/thicks?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thickness = res.data
            });
        },
        async getWidth(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/width?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        async getColour(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/colour?brand=${this.brand ? this.brand : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.colours = res.data
            });
        },
        async getQuality(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/quality?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        cleanChart(){
            var labelNoData = "";
            var label_detail = ""; 
            var container_detail = 'container2';
            var title_detail = 'by Width';
            this.getPieChart([], container_detail, title_detail, label_detail, labelNoData)

            var container_detail_thick = 'container3';
            var title_detail_thick = 'by Thickness'
            this.getPieChart([], container_detail_thick, title_detail_thick, label_detail, labelNoData)

            var container_detail_coat = 'container4';
            var title_detail_coat = 'by As / Az'
            this.getPieChart([], container_detail_coat, title_detail_coat, label_detail, labelNoData)

            var container_detail_grade = 'container5';
            var title_detail_grade = 'by Grade'
            this.getPieChart([], container_detail_grade, title_detail_grade, label_detail, labelNoData)

            var container_detail_quality = 'container6';
            var title_detail_quality = 'by Quality'
            this.getPieChart([], container_detail_quality, title_detail_quality, label_detail, labelNoData)

            var container_detail_color = 'container7';
            var title_detail_color = 'by Color'
            this.getPieChart([], container_detail_color, title_detail_color, label_detail, labelNoData)

            var container_detail_brand = 'container8';
            var title_detail_brand = 'by Brand'
            this.getPieChart([], container_detail_brand, title_detail_brand, label_detail, labelNoData)
        },
        async search(){
            this.loading = true 
            this.detail = false
            this.cleanChart()

            var start_date = this.start_period ? new Date(this.start_period).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end_date = this.end_period ? new Date(this.end_period).toISOString().substr(0, 7).replace(/-/g,"") : ''

            // if (this.machine === '') {
            //     this.snackbar = {
            //       color: "error",
            //       icon: "mdi-alert-circle",
            //       mode: "multi-line",
            //       position: "top",
            //       timeout: 7500,
            //       title: "Error",
            //       text: "Choose machine first",
            //       visible: true
            //     };
            //     return false;
            // }

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/product_analysis?mach_id=${this.machine ? this.machine : ''}&start=${start_date}&end=${end_date}&brand=${this.brand ? this.brand : ''}&coat=${this.coat_mass ? this.coat_mass : ''}&grade=${this.grade ? this.grade : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&colour=${this.colour ? this.colour : ''}&quality=${this.quality ? this.quality : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                console.log(res.data);
                // chartContainer1

                var dt = [];

                for (var i = 0; i < res.data.length; i++) {
                    dt.push({ label: res.data[i].periode , y: parseFloat(res.data[i].total_wgt)});
                }
                var container = 'chartContainer1';
                var title = "CGL"+this.machine+" Summary";
                var label = "CGL"+this.machine+" Summary";

                this.getChart(dt, container, title, label);
            });
        },
        getChart(dt, container, title, label){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                {
                    type: "column",
                    click: this.onClick,
                    name: "ProdSum (TON)",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    // indexLabelFontColor: "#FFF",
                    showInLegend: true,
                    indexLabelPlacement: "outside",  
                    indexLabelOrientation: "horizontal",
                    color: "#75cfb8",
                    // yValueFormatString: "#,###,,,.##",
                }]
            });

            chart.options.data[0].dataPoints = dt;
            this.showDefaultText(chart, "No Data Found!");
            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        }, 
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        }, 
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        }, 
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
            e.chart.render();
        }, 
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async onClick(e){
            var labelNoData = "No Data Found!"
            console.log(e.dataPoint.label);
            var label_detail = "Periode: " + e.dataPoint.label;
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/product_analysis/show?mach_id=${this.machine ? this.machine : ''}&periode=${e.dataPoint.label}&brand=${this.brand ? this.brand : ''}&coat=${this.coat_mass ? this.coat_mass : ''}&grade=${this.grade ? this.grade : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&colour=${this.colour ? this.colour : ''}&quality=${this.quality ? this.quality : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                // 
                this.detail = true

                var dt_detail =  [];

                for (var i = 0; i < res.data.qWidth.length; i++) {

                    dt_detail.push({  label: res.data.qWidth[i].width,  y: parseInt(res.data.qWidth[i].total_wgt), legendText: res.data.qWidth[i].width });

                }
                var container_detail = 'container2';
                var title_detail = 'by Width';
                
                this.getPieChart(dt_detail, container_detail, title_detail, label_detail, labelNoData)
                
                var dt_detail_thick =  [];

                for (var i = 0; i < res.data.qThick.length; i++) {

                    dt_detail_thick.push({  label: res.data.qThick[i].thick,  y: parseInt(res.data.qThick[i].total_wgt), legendText: res.data.qThick[i].thick });

                }
                var container_detail_thick = 'container3';
                var title_detail_thick = 'by Thickness'
                this.getPieChart(dt_detail_thick, container_detail_thick, title_detail_thick, label_detail, labelNoData)


                var dt_detail_coat =  [];

                for (var i = 0; i < res.data.qCoatMass.length; i++) {

                    dt_detail_coat.push({  label: res.data.qCoatMass[i].coat_mass,  y: parseInt(res.data.qCoatMass[i].total_wgt), legendText: res.data.qCoatMass[i].coat_mass });

                }
                var container_detail_coat = 'container4';
                var title_detail_coat = 'by As / Az'
                this.getPieChart(dt_detail_coat, container_detail_coat, title_detail_coat, label_detail, labelNoData)


                var dt_detail_grade =  [];

                for (var i = 0; i < res.data.qGrade.length; i++) {

                    dt_detail_grade.push({  label: res.data.qGrade[i].grade_name,  y: parseInt(res.data.qGrade[i].total_wgt), legendText: res.data.qGrade[i].grade_name });

                }
                var container_detail_grade = 'container5';
                var title_detail_grade = 'by Grade'
                this.getPieChart(dt_detail_grade, container_detail_grade, title_detail_grade, label_detail, labelNoData)

                var dt_detail_quality =  [];

                for (var i = 0; i < res.data.qQuality.length; i++) {

                    dt_detail_quality.push({  label: res.data.qQuality[i].quality_id,  y: parseInt(res.data.qQuality[i].total_wgt), legendText: res.data.qQuality[i].quality_id });

                }
                var container_detail_quality = 'container6';
                var title_detail_quality = 'by Quality'
                this.getPieChart(dt_detail_quality, container_detail_quality, title_detail_quality, label_detail, labelNoData)


                var dt_detail_color =  [];

                for (var i = 0; i < res.data.qColor.length; i++) {

                    dt_detail_color.push({  label: res.data.qColor[i].color_desc,  y: parseInt(res.data.qColor[i].total_wgt), legendText: res.data.qColor[i].color_desc });

                }
                var container_detail_color = 'container7';
                var title_detail_color = 'by Color'
                this.getPieChart(dt_detail_color, container_detail_color, title_detail_color, label_detail, labelNoData)


                var dt_detail_brand =  [];

                for (var i = 0; i < res.data.qBrand.length; i++) {

                    dt_detail_brand.push({  label: res.data.qBrand[i].brand_descr,  y: parseInt(res.data.qBrand[i].total_wgt), legendText: res.data.qBrand[i].brand_descr });

                }
                var container_detail_brand = 'container8';
                var title_detail_brand = 'by Brand'
                this.getPieChart(dt_detail_brand, container_detail_brand, title_detail_brand, label_detail, labelNoData)


                this.$store.dispatch('setOverlay', false)
                showDetail.style.display = "block";

                $('html, body').animate({
                    scrollTop: $("#showDetail").offset().top
                }, 1200)
                
            });
        },
        getPieChart(dt_detail, container_detail, title_detail, label_detail, labelNoData){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });
            pie_chart.options.data[0].dataPoints = dt_detail;
            // this.showDefaultText(pie_chart, "No Data Found!");
            this.showDefaultText(pie_chart, labelNoData);

            if (this.x.matches) {

                for(var i = 0; i < pie_chart.options.data.length; i++){
                    pie_chart.options.data[i].indexLabelFontSize = 6;
                }
                pie_chart.render();
            }
            pie_chart.render();
        }
    },
  }
</script>